import { useState, useEffect } from 'react'
import axios from 'axios'

export const dealerTypes = [
	{ name: 'Elite Dealer', type: 'elite' },
	{ name: 'Robotic Repairs', type: 'roboticrepairs' },
	{ name: 'Spare Parts', type: 'elite' },
	{ name: 'Mineral Swim', type: 'mineralswim' },
	{ name: 'Water Treatment Repairs', type: 'watertreatmentrepairs' },
	{ name: 'Commercial Repairs', type: 'commercial' },
	{ name: 'Pool Builder', type: 'elitebuilder' },
]
export function useAddressSearch(
	searchTerm,
	locale,
	types = 'postcode%2Caddress'
) {
	const [addresses, setAddresses] = useState([])
	useEffect(() => {
		if (searchTerm.trim() !== '') {
			let isFresh = true
			fetchAddresses(searchTerm, locale, types)
				.then(addresses => {
					const features = addresses.features.map(
						({ place_name, geometry }) => ({
							name: place_name,
							coordinates: geometry.coordinates,
						})
					)
					if (isFresh) setAddresses(features)
					return
				})
				.catch(e => e)
			return () => (isFresh = false)
		}
	}, [searchTerm])
	return addresses
}
const addressCache = {}
const dealerCache = {}
const fetchAddresses = async (value, locale, types) => {
	let country
	locale === '' || locale === 'en-AU'
		? (country = 'au')
		: locale === 'en-NZ'
		? (country = 'nz')
		: (country = 'au')
	const ENDPOINT = 'mapbox.places'
	const SEARCH_TEXT = encodeURI(value)
	const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN
	const URI = `https://api.mapbox.com/geocoding/v5/${ENDPOINT}/${SEARCH_TEXT}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&country=${country}&types=${types}&limit=5`
	if (addressCache[value]) {
		return Promise.resolve(addressCache[value])
	}
	try {
		const response = await axios.get(URI)
		if (response.status === 404)
			throw new Error('MapBox found no matches for that address / postcode.')
		return response.data
	} catch (error) {
		// console.log(error)
	}
}
export function useDealerSearch(type, coordinates, setDealer, locale, limit) {
	const [dealers, setDealers] = useState([])
	// console.log('dealers', dealers)
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		if (type && Array.isArray(coordinates) && coordinates.length === 2) {
			let isFresh = true
			const filter = JSON.stringify({ type, status: 'active' })
			setLoading(true)
			fetchDealers(filter, coordinates, 3000, 3, locale, limit)
				.then(data => {
					if (isFresh) {
						const hasOne = data.length === 1
						const hasTwo = data.length <= 2
						const inCluster =
							/elitebuilder|mineralswim|roboticrepairs|watertreatmentrepairs|commercial/i.test(
								type
							) ||
							(hasTwo && data[1].meta.distance <= 6000) ||
							(hasOne && data[0].meta.distance <= 6000)
						// console.log('dealers-result', data)
						const cluster = inCluster
							? data && data.length >= 4
								? data.slice(0, limit - 1)
								: data
							: [data[0]]
						// console.log('dealers-cluster', cluster)
						setDealers(cluster)
						setDealer(cluster[0])
						setLoading(false)
					}
					return
				})
				.catch(e => e)
			return () => (isFresh = false)
		}
	}, [coordinates, setDealer, type])
	return { dealers, loading }
}
export async function dealerSearchFunction(type, coordinates, setDealer, locale, limit) {
	let dealers = []
		if (type && Array.isArray(coordinates) && coordinates.length === 2) {
			const filter = JSON.stringify({ type, status: 'active' })
			await fetchDealers(filter, coordinates, 3000, 3, locale, limit)
				.then(data => {
						const hasOne = data.length === 1
						const hasTwo = data.length <= 2
						const inCluster =
							/elitebuilder|mineralswim|roboticrepairs|watertreatmentrepairs|commercial/i.test(
								type
							) ||
							(hasTwo && data[1].meta.distance <= 6000) ||
							(hasOne && data[0].meta.distance <= 6000)
						const cluster = inCluster
							? data && data.length >= 4
								? data.slice(0, limit - 1)
								: data
							: [data[0]]
						dealers = cluster
					return
				})
				.catch(e => e)
		}
	return  dealers 
}
const fetchDealers = async (
	filter,
	coordinates,
	delay,
	tries,
	locale,
	limit = 2
) => {
	locale === '' || locale === 'en-NZ' ? (limit = 1) : limit
	const url = '/.netlify/functions/graphql'
	const variables = {
		query: filter,
		coordinates,
		limit: limit,
		maxDistance: 1400000,
	}
	// console.log(variables)
	const query = `query dealersByDistance($query:String,$coordinates:[Float!],$maxDistance:Int,$limit:Int) {dealersByDistance(query:$query,coordinates:$coordinates,maxDistance:$maxDistance,limit:$limit) {_id,affiliateId,name,email,phone,social{minisite},address{fullAddress,city,state,postalCode},logo,location{coordinates},branch{is_busy},meta{distance},type,salesForceId}}`
	if (dealerCache[filter && coordinates]) {
		return Promise.resolve(dealerCache[filter && coordinates])
	}
	const onError = async error => {
		let triesLeft = tries - 1
		if (!triesLeft) throw error
		return wait(delay).then(() =>
			fetchDealers(filter, coordinates, delay, triesLeft)
		)
	}
	try {
		const res = await axios.post(url, { query, variables })
		// console.log('Result: ', res)
		// console.log('Request: ', { query, variables })
		const result = res.data.data.dealersByDistance.filter(d => d !== null)
		return result
	} catch (error) {
		await onError(error)
	}
}
const wait = delay => new Promise(resolve => setTimeout(resolve, delay))
